import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TechGalleryContent from ".././components/GalleryContent/TechGalleryContent"
import StorageGalleryContent from ".././components/GalleryContent/StorageGalleryContent"
import MerchGalleryContent from ".././components/GalleryContent/MerchGalleryContent"

const HeaderAccessories = () => (
  <Layout>
    <SEO title="Accessories" />
    <h1>Accessories</h1>
    <TechGalleryContent />
    <StorageGalleryContent />
    <MerchGalleryContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default HeaderAccessories
